<script>
import Vue from "vue";

const EMPTY_SEARCH = {
  drsSki: null,
  location: null,
  title: null,
  category: null,
  drsSubcat: null,
  drsBuy: null,
  tags: [],
  priceLow: null,
  priceHigh: null,
  onlyPublished: true,
};
export default {
  name: "InventoryAdvancedSearch",
  components: {},
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    search() {
      if (this.parsedFilter && this.parsedFilter.isAdvanced) {
        return this.parsedFilter.data;
      }

      return this.buildEmptySearch();
    },
    onlyPublishedChecked: {
      get() {
        return (
          this.search.onlyPublished === true ||
          this.search.onlyPublished === undefined ||
          this.search.onlyPublished === null
        );
      },
      set(newOnlyPublishedValue) {
        Vue.set(this.search, "onlyPublished", newOnlyPublishedValue);
      },
    },
    payload() {
      var payload = {};
      if (this.search.drsSki !== null) {
        payload.drsSki = this.search.drsSki;
      }
      if (this.search.location !== null) {
        payload.location = this.search.location;
      }
      if (this.search.title !== null) {
        payload.title = this.search.title;
      }
      if (this.search.category !== null) {
        payload.category = this.search.category;
      }
      if (this.search.drsSubcat !== null) {
        payload.drsSubcat = this.search.drsSubcat;
      }
      if (this.search.drsBuy !== null) {
        payload.drsBuy = this.search.drsBuy;
      }
      if (this.search.tags && this.search.tags.length > 0) {
        payload.tags = this.search.tags;
      }
      if (this.search.priceLow) {
        payload.price = [this.search.priceLow];
      }
      if (this.search.priceHigh) {
        payload.price = payload.price
          ? [...payload.price, this.search.priceHigh]
          : [undefined, this.search.priceHigh];
      }
      if (!this.search.onlyPublished)
        payload.onlyPublished = this.search.onlyPublished;
      return payload;
    },
  },
  methods: {
    buildEmptySearch() {
      return Object.assign({}, EMPTY_SEARCH);
    },
    cancel() {
      this.$emit("cancel");
    },
    update() {
      // TODO - this should be not payload but a normal object, not some hacky way of parsing the input (when not needed)!
      this.$emit("update", this.payload);
    },
  },
};
</script>

<template>
  <v-form ref="advancedSearchForm" class="d-flex flex-column">
    <div>
      <div class="text-overline">DRS</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex">
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.drsSki"
          :label="'DRS SKI'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.drsSubcat"
          :label="'DRS Subcat'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.drsBuy"
          :label="'DRS Buy'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>
    <div class="mt-6">
      <div class="text-overline">Inventory item</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex">
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.location"
          :label="'Location'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.title"
          :label="'Title'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.category"
          :label="'Category'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="mr-sm-2 flex-grow-1">
        <v-combobox
          v-model="search.tags"
          :label="'Tags'"
          multiple
          small-chips
          hide-details
          hint="Type and press 'Enter' to add a tag to the search"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="align-self-center mt-4 pt-1 text-body-1">Price Range</div>
      <div class="ml-sm-2">
        <v-text-field
          v-model="search.priceLow"
          prefix="$"
          type="number"
          label="Low"
          autocomplete="no"
          :max="search.priceHigh ? search.priceHigh - 0.01 : false"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="ml-sm-3">
        <v-text-field
          v-model="search.priceHigh"
          prefix="$"
          type="number"
          label="High"
          :min="search.priceLow ? search.priceLow + 0.01 : false"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="ml-sm-2">
        <!-- This is not nicely bound to v-model, but instead it is: -->
        <!--   - readonly, bound to input-value and changed on click event -->
        <!--   - if not done with readonly and change on click - we have problems when programatically changing this -->
        <v-checkbox
          hide-details
          readonly
          :input-value="onlyPublishedChecked"
          label="Only show items on Shopify"
          @click="onlyPublishedChecked = !onlyPublishedChecked"
        />
      </div>
    </div>
    <div class="d-sm-flex mt-4">
      <v-spacer />
      <v-btn class="elevation-0" text @click="cancel">Cancel</v-btn>
      <v-btn class="ml-4 elevation-0" color="accent" text @click="update"
        >Search</v-btn
      >
    </div>
  </v-form>
</template>

<style></style>
